import {Guid} from "guid-typescript";

export default class SiteValueId{
    public coefficientSelectedValueId:string;
    public siteId!:string;
    public trackId!:string;
    public siteName!:string;
    constructor() {
        this.coefficientSelectedValueId = Guid.create().toString();
    }
}