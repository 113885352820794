import SiteValueId from "@/models/SiteValueId";
import {Guid} from "guid-typescript";

export  default class GroupedType5CoefficientSelectedValueByValue{
    public trackId:string;
    public coefficientId:string;
    public bopModelId:string;
    public value:number;
    public siteValueIdList: Array<SiteValueId>
    constructor(coefficientId:string, bopModelId:string) {
        this.coefficientId = coefficientId;
        this.bopModelId = bopModelId;
        this.trackId = Guid.create().toString();
        this.value = 0;
        this.siteValueIdList = [];
    }

}