



































import {Component, Prop, Vue} from "vue-property-decorator";
import UnitService from "@/services/UnitService";
import CoefficientSelectedValueService, {type5SaveRequest} from "@/services/CoefficientSelectedValueService";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import {UserRoleEnum,} from "@/utils/Enums";
import Constants from "@/utils/Constants";
import ExportService from "@/services/ExportService";
import GroupedType5CoefficientSelectedValueByValue from "@/models/GroupedType5CoefficientSelectedValueByValue";
import {Guid} from "guid-typescript";
import SiteValueId from "@/models/SiteValueId";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};
type coefficientSelectedValueError = {
  code: string,
  value:number,
  siteName:string
}
@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    CoefficientSitesTableModal: () => import("@/components/Shared/Table/CoefficientSitesTableModal.vue"),
  },
})
export default class CoefficientPerSiteSetup extends Vue {
  @Prop({ required: true }) public bopModelId!: string;
  @Prop({ required: true }) public bopModelKey!: string;
  @Prop({ required: true }) public coefficientId!: string;
  private showModal:boolean = false;

  private coefficientSelectedValuesToDelete:Set<string> = new Set<string>();
  private propsCoefficientSitesTableModal:any = null;
  public data: Data = {
    columns: [],
    rows: [],
  };
  private get exportBopModelUrl():string {
    return ExportService.exportBopModelUrl(this.bopModelKey,this.language);
  }
  private newDataHashSet:Set<string> = new Set<string>();
  private refreshKey:string = "";
  private initialData: Array<GroupedType5CoefficientSelectedValueByValue>|null = null;
  private modifiedCoefficientConfigs: Array<GroupedType5CoefficientSelectedValueByValue> = [];
  private get language():string{
    return this.$i18n.locale;
  }
  /**
   * Methods
   */
  private onDuplicateData(newData:GroupedType5CoefficientSelectedValueByValue){
    // newData.siteValueIdList = [];
    setTimeout(()=> {
      const index = this.modifiedCoefficientConfigs.findIndex(el => el.trackId === newData.trackId);

      newData.siteValueIdList = newData.siteValueIdList.map(s => {
        const siteValueId = new SiteValueId();
        siteValueId.siteId = s.siteId;
        siteValueId.siteName = s.siteName;
        return siteValueId;
      });
      this.modifiedCoefficientConfigs[index] = newData;
      this.newDataHashSet.add(newData.trackId);
      this.$nextTick().then(()=> {
        this.$store.commit('changeHasBeenModified', true);
      });
    },100);

  }
  private  onCoefficientSitesTableModalValidate(result:{coefficientSelectedValuesToSave:GroupedType5CoefficientSelectedValueByValue,coefficientSelectedValuesToDelete:Array<string>}){
    console.log(result);
    result.coefficientSelectedValuesToDelete.forEach(s => {
      this.coefficientSelectedValuesToDelete.add(s);
    });
    (<any>this.$refs.table).modify(result.coefficientSelectedValuesToSave);
    this.showModal = false;
    result.coefficientSelectedValuesToSave.siteValueIdList.forEach(v => {
      if(this.coefficientSelectedValuesToDelete.has(v.coefficientSelectedValueId)){
        this.coefficientSelectedValuesToDelete.delete(v.coefficientSelectedValueId);
      }
    });
  }
  // Open modal based on property
  public openModal(props: any) {
    this.propsCoefficientSitesTableModal = props;
    this.showModal = true;
  }
  private closeModal(){

    this.showModal = false;
  }
  private showSuccessNotification(){
    this.$notify({
      group: "global",
      type: "success",
      title: this.$t(
          "bopModelSetup.coefficientSetup.deleteSuccessTitle"
      ).toString(),
      text: this.$t(
          "bopModelSetup.coefficientSetup.deleteSuccess"
      ).toString(),
    });
  }
  private async remove(data: any) {
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      this.$t("bopModelSetup.coefficientSetup.confirmDelteMessage").toString(),
      {
        title: this.$t(
          "bopModelSetup.coefficientSetup.confirmDelteTitle"
        ).toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: this.$t(
          "bopModelSetup.coefficientSetup.confirmDelteYes"
        ).toString(),
        cancelTitle: this.$t(
          "bopModelSetup.coefficientSetup.confirmDelteNo"
        ).toString(),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        dialogClass: "modal-app",
      }
    );

    if (modalResponse) {
      // console.log(data);
      const rowData:GroupedType5CoefficientSelectedValueByValue = data.rowData;
      try {
        if(this.newDataHashSet.has(rowData.trackId)){

          this.modifiedCoefficientConfigs.splice(data.rowIndex,1);
          (<any>this.$refs.table).deleteRow(data.rowIndex);
          this.showSuccessNotification();
          return;
        }
        const response = await CoefficientSelectedValueService.deleteType5CoefficientBySiteIds(this.coefficientId,this.bopModelId,rowData.value,this.language);

        if (response.status === 200) {
          this.showSuccessNotification();
          (<any>this.$refs.table).deleteRow(data.rowIndex);
        }
      } catch (err) {
        const errResponse = err.response;
        let text = this.$t(
          "bopModelSetup.coefficientSetup.deleteError"
        ).toString();
        if (errResponse.status === 500) {
          const errData:coefficientSelectedValueError = errResponse.data;
          switch (errData.code){
            case "CoefficientSelectedValueUsedInBop":
              text = this.$t(
                  "bopModelSetup.coefficientSetupT5.deleteErrorUsedInBop"
              ).toString().replace("{value}",errData.value.toString()).replace("{site}",errData.siteName);
              break;
          }
        }
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t(
            "bopModelSetup.coefficientSetup.deleteErrorTitle"
          ).toString(),
          text,
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
        });
      }
    }
  }

  public async save() {
    if (this.modifiedCoefficientConfigs.length) {
      try {
        const postData:type5SaveRequest = {
          coefficientSelectedValuesToDelete: Array.from(this.coefficientSelectedValuesToDelete.values()),
          coefficientSelectedValuesToSave: this.modifiedCoefficientConfigs,
          languageCode: this.language
        }

        const response = await CoefficientSelectedValueService.saveCoefficientType5Values(
            postData
        );

        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.coefficientConfig.saveSuccess.title"
            ).toString(),
            text: this.$t(
              "bopModelSetup.coefficientConfig.saveSuccess.text"
            ).toString(),
          });
          await this.refresh();
        }
      } catch (err) {
        if (err.response.status === 500) {
          let text = this.$t(
            "bopModelSetup.coefficientConfig.saveError.text"
          ).toString();
          const errData:coefficientSelectedValueError = err.response.data;
          switch (errData.code){
            case "CoefficientSelectedValueUsedInBop":
              text = this.$t(
                  "bopModelSetup.coefficientSetupT5.deleteErrorUsedInBop"
              ).toString().replace("{value}",errData.value.toString()).replace("{site}",errData.siteName);
              break;
            case "DuplicateCoefficientSelectedValue":
              text = this.$t(
                  "bopModelSetup.coefficientSetupT5.duplicateCoefficientSelectedValue"
              ).toString().replace("{value}",errData.value.toString()).replace("{site}",errData.siteName);
              break;
          }
          this.$notify({
            group: "globalError",
            type: "error",
            title: this.$t(
              "bopModelSetup.coefficientConfig.saveError.title"
            ).toString(),
            text,
            duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
          });
        }
      }
    }
  }
  public async addData() {
    const newCoefficientConfig = new GroupedType5CoefficientSelectedValueByValue(this.coefficientId,this.bopModelId);
    this.newDataHashSet.add(newCoefficientConfig.trackId);
    (<any>this.$refs.table).updatedRows.unshift(newCoefficientConfig);
    (<any>this.$refs.table).modify(newCoefficientConfig);
  }

  private get isNotAllowedToEdit(): Boolean {
    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
  }

  public async refresh() {
    this.modifiedCoefficientConfigs = [];
    this.initialData = (await CoefficientSelectedValueService.getCoefficientType5(
        this.coefficientId,
        this.bopModelId,
        this.language
    )).data;
    this.refreshKey = Guid.create().toString();
  }
  private  async loadData(){
    this.initialData = (await CoefficientSelectedValueService.getCoefficientType5(
        this.coefficientId,
        this.bopModelId,
        this.language
    )).data;
    this.data.columns = [
      {
        name: "editable",
        title: this.$t("bopModelSetup.coefficientValue"),
        property: "value",
        sortField: "value",
      },
      {
        name: "coefficientSitesField",
        title: this.$t("bopModelSetup.coefficientSite"),
      },
      {
        name: "actions",
        title: "",
        removeButton: true,
        editButton: true,
        duplicateButton: true,
        disableRemoveButton: this.isNotAllowedToEdit,
        disableEditButton: this.isNotAllowedToEdit,
        disableDuplicateButton: this.isNotAllowedToEdit,
      }
    ];
  }
  /**
   * Hooks
   */
  public created() {
    this.loadData();
  }
}
